import React, { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import { DropdownTitle } from "./QuadrantFilters.Styled";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchQuadrantFiltersData,
  setDefaultQuadrantFilters,
} from "../../../../../store/actions//AssortmentTool/assortmentFilters.action";
import AssortmentDropdown from "../../../../../components/AssortmentDropdown";
import {
  fetchAssortmentQuadrantChartData,
} from "../../../../../store/actions//AssortmentTool/assortmentDashboard.action";
import { makeStyles } from "@material-ui/core/styles";
import Skeletons from "../../../Skeletons/Skeletons";
import { API_URL } from "../../../../../store/actions/constants";

const useStyles = makeStyles((theme) => ({
  asterik: { color: "red" },
}));

export const initialState = {
  xAxis: "Profit Percentage",
  yAxis: "Net Revenue Incremental",
  bubbleSize: "Volume",
};

const QuadrantFilters: React.FC<{
  data;
  onSelectFilters;
  defaultFilters?;
  clearFilter?: boolean;
  selectedGeoFilters?;
}> = ({
  data,
  onSelectFilters,
  clearFilter,
  selectedGeoFilters,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const AssortmentFiltersData = useSelector(
    (state) => state.AssortmentFiltersReducer
  );

  const [prevAssortmentFiltersData, setPrevAssortmentFiltersData] = useState(
    AssortmentFiltersData
  );

  const [selectedQuadrantFilters, setSelectedQuadrantFilters] = useState({
    ...initialState,
  });

  const [disabledGeoFilters, setDisabledFilters] = useState({
    xAxis: false,
    yAxis: false,
    bubbleSize: false,
  });

  const [selectedValues, setSelectedValues] = useState({
    xAxis: "Profit Percentage",
    yAxis: "Net Revenue Incremental",
    bubbleSize: "Volume",
  });

  const [xAxes, setXAxes] = useState("");
  const [yAxes, setYAxes] = useState("");
  const [bubbleSizes, setBubbleSizes] = useState("");
  const [defaultState, setDefaultState] = useState(true);

  const handleFilterChange = async (filterKey, handleFunction, data) => {
    try {
      const response = data[filterKey];
      const filterObjects = response.map((item) => ({
        id: item,
        label: item,
        value: item,
        default: false,
      }));
      handleFunction(filterObjects);
    } catch (error) {
      console.error(`Error handling ${filterKey} data:`, error);
    }
  };

  useEffect(() => {
    // Check if "xAxis" data has changed
    if (
      AssortmentFiltersData.xAxis !== prevAssortmentFiltersData.xAxis &&
      AssortmentFiltersData.xAxis !== null &&
      AssortmentFiltersData.xAxis !== undefined &&
      AssortmentFiltersData.xAxis !== ""
    ) {
      handleFilterChange("xAxis", setXAxes, AssortmentFiltersData);
    }

    // Check if "yAxis" data has changed
    if (
      AssortmentFiltersData.yAxis !== prevAssortmentFiltersData.yAxis &&
      AssortmentFiltersData.yAxis !== null &&
      AssortmentFiltersData.yAxis !== undefined &&
      AssortmentFiltersData.yAxis !== ""
    ) {
      handleFilterChange("yAxis", setYAxes, AssortmentFiltersData);
    }

    // Check if "bubbleSize" data has changed
    if (
      AssortmentFiltersData.bubbleSize !==
        prevAssortmentFiltersData.bubbleSize &&
      AssortmentFiltersData.bubbleSize !== null &&
      AssortmentFiltersData.bubbleSize !== undefined &&
      AssortmentFiltersData.bubbleSize !== ""
    ) {
      handleFilterChange("bubbleSize", setBubbleSizes, AssortmentFiltersData);
    }
  }, [AssortmentFiltersData, prevAssortmentFiltersData]);

  useEffect(() => {
    if (selectedQuadrantFilters) {
      onSelectFilters({ ...selectedQuadrantFilters });
    }
  }, [selectedQuadrantFilters]);

  useEffect(() => {
    if (clearFilter) {
      setSelectedQuadrantFilters({ ...initialState });
    }
  }, [clearFilter]);

  const callQuadrantLevels = async () => {
    await onChangeQuadrantLevel("", []);
    await updateAndDispatchValues({
      xAxis: "Profit Percentage",
      yAxis: "Net Revenue Incremental",
      bubbleSize: "Volume",
    });
    await onChangeQuadrantLevel("xAxis", ["Profit Percentage"]);
    await onChangeQuadrantLevel("yAxis", ["Net Revenue Incremental"]);
  };

  useEffect(() => {
      if(AssortmentFiltersData.uid !== null){
        callQuadrantLevels();
      }
  }, [AssortmentFiltersData.uid]);

  useEffect(() => {
    const { channel, region, storeSegment } = selectedGeoFilters;
    if (channel && region && storeSegment) {
      setDefaultState(false);
      onChangeQuadrantLevel("bubbleSize", ["Volume"]);
    }
  }, [selectedGeoFilters]);

  const updateAndDispatchValues = async (updatedValues) => {
    setSelectedValues(updatedValues);
    setSelectedQuadrantFilters(updatedValues);
    dispatch(setDefaultQuadrantFilters(updatedValues));
  };

  const onChangeQuadrantLevel = (key, data) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: data[0],
    }));

    const payload = { ...selectedQuadrantFilters, [key]: data[0] };
    if (key === "bubbleSize") {
      handleChangesForOtherFilters(
        { [key]: data[0] },
        key,
        API_URL.ASSORTMENT.QUADRANT_GRAPH_DATA
      );
      setSelectedQuadrantFilters(payload);
      dispatch(setDefaultQuadrantFilters(payload));
    } else {
      handleChangesForOtherFilters(
        { [key]: data[0] },
        key,
        API_URL.ASSORTMENT.QUADRANT_FILTER_OPTIONS
      );
    }
    onSelectFilters({ ...payload });
  };

  const handleChangesForOtherFilters = (data, key, apiPath) => {
    let payload = {};
    const updatedValues = {
      xAxis:
        key === "xAxis" || key === "yAxis" || key === "bubbleSize"
          ? selectedValues.xAxis
          : "",
      yAxis:
        key === "yAxis" || key === "bubbleSize" ? selectedValues.yAxis : "",
      bubbleSize: key === "bubbleSize" ? selectedValues.bubbleSize : "",
      ...data,
    };
    if (!defaultState) {
      setSelectedValues(updatedValues);
      setSelectedQuadrantFilters(updatedValues);
      dispatch(setDefaultQuadrantFilters(updatedValues));
    }

    if (key === "bubbleSize") {
      const { channel, region, storeSegment } = selectedGeoFilters;
      payload = {
        xAxis: selectedValues.xAxis,
        yAxis: selectedValues.yAxis,
        bubbleSize: selectedValues.bubbleSize,
        channel,
        region,
        storeSegment,
        uid: AssortmentFiltersData.uid,
        ...data,
      };
      dispatch(fetchAssortmentQuadrantChartData(payload, apiPath));
    } else {
      payload = {
        xAxis: key === "xAxis" || key === "yAxis" ? updatedValues.xAxis : "",
        yAxis: key === "yAxis" ? updatedValues.yAxis : "",
        bubbleSize: "",
        ...data,
      };
      dispatch(fetchQuadrantFiltersData(payload, apiPath));
    }
  };

  return (
    <Grid container justifyContent="flex-end">
      {true ? (
        <>
          <Grid item style={{ marginLeft: 0 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <DropdownTitle style={{ marginTop: "10px", marginRight: "15px" }}>
                {data.xAxis.title}
                <span className={classes.asterik}>*</span>
              </DropdownTitle>

              <AssortmentDropdown
                disabled={disabledGeoFilters.xAxis}
                data={xAxes}
                onChange={(data) => onChangeQuadrantLevel("xAxis", data)}
                placeholder={data.xAxis.placeholder}
                multiple={data.xAxis.multiple}
                allOption={data.xAxis.all}
                defaultOption={
                  selectedValues.xAxis ? [selectedValues.xAxis] : []
                }
              />
            </div>
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <DropdownTitle style={{ marginTop: "10px", marginRight: "15px" }}>
                {data.yAxis.title}
                <span className={classes.asterik}>*</span>
              </DropdownTitle>
              <AssortmentDropdown
                disabled={disabledGeoFilters.yAxis}
                data={yAxes}
                onChange={(data) => onChangeQuadrantLevel("yAxis", data)}
                placeholder={data.yAxis.placeholder}
                multiple={data.yAxis.multiple}
                allOption={data.yAxis.all}
                defaultOption={
                  selectedValues.yAxis ? [selectedValues.yAxis] : []
                }
              />
            </div>
          </Grid>
          <Grid item style={{ marginLeft: "20px" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                verticalAlign: "center",
                alignContent: "center",
                justifyContent: "center",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <DropdownTitle style={{ marginTop: "10px", marginRight: "15px" }}>
                {data.bubbleSize.title}
                <span className={classes.asterik}>*</span>
              </DropdownTitle>
              <AssortmentDropdown
                disabled={disabledGeoFilters.bubbleSize}
                data={bubbleSizes}
                onChange={(data) => onChangeQuadrantLevel("bubbleSize", data)}
                placeholder={data.bubbleSize.placeholder}
                multiple={data.bubbleSize.multiple}
                allOption={data.bubbleSize.all}
                defaultOption={
                  selectedValues.bubbleSize ? [selectedValues.bubbleSize] : []
                }
              />
            </div>
          </Grid>
        </>
      ) : (
        <Skeletons type={"QuadrantFilters"} />
      )}
    </Grid>
  );
};

export default QuadrantFilters;
